import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Layout, RandomOp, Typography } from '../../components';
import {
  MainTitle, MainPart, Figure, List,
} from '../../components/opComponents';

const EpicondyliteLaterale = () => (
  <Layout
    title="Epicondylite latérale : Diagnostic et traitement"
    description="L'épicondylite latérale ou « Tennis Elbow » est une inflammation des tendons des muscles épicondyliens. Traitement efficace 92% des cas."
    headerStyle="white"
  >
    <div className="flex flex-col px-4 py-16 mx-auto space-y-4 max-w-screen-mdx">
      <Link to="/chirurgie-du-coude" className="flex flex-row items-center space-x-3 uppercase text-primary group">
        <FontAwesomeIcon className="transition-all duration-300 ease-in-out group-hover:text-link" icon="long-arrow-alt-left" />
        <Typography className="group-hover:text-link" weight="semiBold" transition="medium">Retour aux opérations du coude</Typography>
      </Link>
      <div className="flex flex-col space-y-6">
        <MainTitle currentOpTitle="Epicondylite latérale" />
        <MainPart title="Qu'est-ce qu'une épicondylite latérale ?">
          <Typography weight="bold">
            L&apos;épicondylite latérale ou « Tennis Elbow »
            {' '}
            <Typography variant="span">
              est une inflammation des tendons des muscles épicondyliens qui s&apos;insèrent
              sur la face externe du coude (épicondyle). Il s&apos;agit d&apos;une pathologie
              fréquente, qui ne se rencontre pas exclusivement chez les sportifs
              et dont la fréquence est estimée à 1-3% dans la population générale. Les symptôme
              s apparaissent entre 30 et 50 ans, atteignant plus volontiers le coté dominant,
              avec une légère prédominance masculine.
            </Typography>
          </Typography>
        </MainPart>
        <MainPart title="Quels sont les muscles épicondyliens ?">
          <Typography>
            Le muscle 2ème radial ou « Extensor Carpi Radialis Brevis » (ECRB) est
            le muscle épicondylien qui est le plus souvent impliqué dans les épicondylalgies.
          </Typography>
          <Typography>
            La branche postérieure du nerf radial (BPNR) qui assure l&apos;innervation de tous
            les muscles épicondyliens, passe entre les deux faisceaux du muscle
            supinateur et peut être comprimé dans certains cas (Figure 1).
          </Typography>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure1-epicondylitelaterale.jpg"
            caption="Fgure 1. Anatomie de la face externe du coude."
            currentOpTitle="Epicondylite Laterale"
          />
        </MainPart>
        <MainPart title="Quelles sont les causes possibles de l'épicondylalgie ?">
          Les principales causes de l&apos;épicondylalgie latérale sont :
          {' '}
          <Typography weight="bold">
            <Typography variant="span">
              l&apos;inflammation des tendons des muscles épicondyliens, la
              compression de la BPNR ainsi que toute pathologie de l&apos;articulation du coude.
            </Typography>
          </Typography>
          <Typography>
            En dehors d&apos;une pathologie articulaire évidente, un traumatisme direct sur le coude
            ou les microtraumatismes répétés semblent être à l&apos;origine de cette pathologie,
            responsables de micro puis de macro-ruptures des muscles épicondyliens.
          </Typography>
          <Typography>
            Ces ruptures favorisent l&apos;apparition d&apos;hématomes avec formation
            de tissu de cicatrisation
            de mauvaise qualité. Cette « mauvaise cicatrisation » est à l&apos;origine
            d&apos;une rétraction
            des muscles favorisant elle même une nouvelle rupture entretenant ainsi ce cercle
            vicieux (Figure 2).
          </Typography>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure2-epicondylitelaterale.jpg"
            caption="Fgure 2. Cercle vicieux pérennisant l&apos;épicondylite latérale en l&apos;absence d&apos;une prise en charge adaptée"
            currentOpTitle="Epicondylite Laterale"
          />
        </MainPart>
        <MainPart title="Quels sont les symptômes d'une épicondylite latérale ?">
          <Typography>
            L&apos;inflammation de l&apos;insertion des muscles épicondyliens est facilement
            mise en évidence par la palpation de la face externe du coude,
            ainsi qu&apos;à l&apos;extension contre résistance du poignet, le coude
            étant maintenu en extension.
            {' '}
            <Typography variant="span" weight="bold">
              Ces deux tests peuvent être bien évidemment combinés lors de l&apos;examen clinique
            </Typography>
            {' '}
            (Figure 3)
          </Typography>
          <Figure
            maxWidth="max-w-[500px]"
            imageName="figure3-epicondylitelaterale.jpg"
            caption="Figure 3. Diagnostic clinique de l&apos;épicondylite latérale : reproduction des douleurs à la palpation de la face externe du coude ainsi que lors de l&apos;&apos;extension du poignet contre résistance"
            currentOpTitle="Epicondylite Laterale"
          />
        </MainPart>
        <MainPart title="Quels sont les examens qui permettent de mettre en évidence une épicondylite latérale ?">
          <Typography>
            Les examens complémentaires ne sont en général
            pas indispensables pour faire le diagnostic d&apos;épicondylite.
          </Typography>
          <Typography>
            Les radiographies du coude sont souvent normales, elles peuvent
            mettre en évidence des calcifications au niveau de l&apos;insertion
            des tendons épicondyliens.
          </Typography>
          <Typography>
            L&apos;IRM permet de voir les tendons des muscles épicondyliens latéraux,
            le nerf radial et ses branches ainsi que l&apos;articulation du coude.
          </Typography>
        </MainPart>
        <MainPart title="Quels sont le traitement médical et le traitement chirurgical de l'épicondylite latérale">
          <Typography>
            Le traitement médical est
            {' '}
            <a className="underline text-link hover:text-primary" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7545972/" target="_blank" rel="noreferrer">efficace</a>
            {' '}
            dans plus de 92% des cas.
          </Typography>
          <Typography>
            Le repos occupe une place fondamentale pendant la phase
            douloureuse. Plusieurs traitements en association peuvent être proposés.
          </Typography>
          <Typography>
            Leur échec doit conduire à un changement rapide pour
            éviter toute chronicisation des symptômes :
          </Typography>
          <List>
            <List.Item>
              <Typography>
                Le glaçage est surtout utilisé en cas de crise aiguë.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Les anti-inflammatoires non stéroïdiens
                peuvent être utilisés en comprimés ou en pommade.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                L&apos;infiltration de corticoïdes, réalisée en dernier
                recours avant la chirurgie, est efficace à cour
                terme sur la douleur et la fonction dans plus de 90% des
                cas. Les Infiltrations de corticoïdes ont malheureusement
                des effets secondaires au niveau du site d&apos;injection :
                dépigmentation cutanée, nécrose de la graisse sous-cutanée,
                diminution de la cicatrisation et fragilisation tendineuse.
                Ces effets secondaires sont observés en cas d&apos;injections répétées
                (supérieures à deux voire trois injections).
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Les ondes de choc, ont une efficacité discutable. Le patient
                doit être prévenu qu&apos;elles sont en général douloureuses.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                L&apos;injection de PRP ou de « Platelet Rich Plasma »,
                réalisée à partir d&apos;une
                simple prise de sang, suivie de centrifugation, contient des facteurs
                de croissances. Son efficacité a été prouvée dans de nombreuses études.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                La rééducation, très efficace à long terme, est faite dès la disparition
                des douleurs au repos. L&apos;objectif est de renforcer la souplesse, la
                musculature et l&apos;endurance. Le travail excentrique
                (étirement des muscles contractés)
                et les massages transverses profonds (réalisés dans le sens
                perpendiculaire aux fibres tendineuses), sont essentiels (Figure 4).
              </Typography>
            </List.Item>
          </List>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure4-epicondylitelaterale.jpg"
            caption="Figure 4. Travail excentrique : extension active du poignet contre résistance"
            currentOpTitle="Epicondylite Laterale"
          />
          <Typography>
            Les attelles de repos et les bracelets épicondyliens sont
            très peu efficaces sur les symptômes de l&apos;épicondylite.
          </Typography>
          <Typography>
            Le traitement chirurgical est indiqué en cas d&apos;échec du
            traitement médical bien conduit pendant au moins 6 mois.
          </Typography>
          <Typography>
            L&apos;intervention est le plus souvent réalisée sous anesthésie
            loco-régionale (le bras est uniquement endormi), en ambulatoire
            (pas d&apos;hospitalisation).
          </Typography>
          <Typography>
            Elle peut être faite à ciel ouvert ou sous arthroscopie, le principe
            étant de réaliser un allongement des muscles épicondyliens. L&apos;avantage
            de la chirurgie à ciel ouvert est de pouvoir décomprimer le
            nerf radial pendant le même temps opératoire (Figure 5, 6).
          </Typography>
          <div className="flex flex-col items-start space-x-0 space-y-8 mdx:flex-row mdx:space-x-8 mdx:space-y-0">
            <Figure
              maxWidth="max-w-[400px]"
              imageName="figure5-epicondylitelaterale.jpg"
              caption="Figure 5. Réalisation d&apos;incisions de décharge au niveau du muscle 2ème radial (ECRB) et ouverture du supinateu"
              currentOpTitle="Epicondylite Laterale"
            />
            <Figure
              maxWidth="max-w-[400px]"
              imageName="figure6-epicondylitelaterale.jpg"
              caption="Figure 6. Allongement du muscle 2ème radial (ECRB) et ouverture du supinateur permettant ainsi de libérer la branche postérieure du nerf radial (BPNR)"
              currentOpTitle="Epicondylite Laterale"
            />
          </div>
        </MainPart>
        <MainPart title="Quelles sont les suites opératoires après une opération chirurgicale pour une épicondylite latérale ?">
          <Typography>
            Le coude est immobilisé dans une écharpe pendant une dizaine de jours.
            Le patient commence en général les séances de rééducation à partir
            de la 2ème semaine post-opératoire, dès l&apos;ablation des fils.
            L&apos;utilisation de force est à proscrire au niveau du coude pour
            une durée de deux mois.
          </Typography>
        </MainPart>
        <MainPart title="Quelles sont les complications possibles après une opération chirurgicale pour une épicondylite latérale ?">
          <Typography size="mdx" weight="bold">Complications non spécifiques :</Typography>
          <List>
            <List.Item>
              <Typography>
                Infection du site opératoire pouvant nécessiter une
                intervention de nettoyage associée à la prise d&apos;antibiotiques
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Hématome pouvant nécessiter une évacuation en cas
                de menace cutanée ou compression nerveuse
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Algodystrophie. Son apparition est indépendante du type de chirurgie.
                Elle évolue en deux phases : phase chaude (main gonflée, douloureuse
                avec transpiration) puis froide (prédominance de la raideur). L&apos;évolution
                est le plus souvent longue (12-18 mois) et des séquelles sont
                possibles (douleur et/ou raideur des articulations des
                doigts et/ou poignet et/ou épaule)
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Accidents liés à l&apos;anesthésie
              </Typography>
            </List.Item>
          </List>
          <Typography size="mdx" weight="bold">Complications spécifiques :</Typography>
          <List>
            <List.Item>
              <Typography>
                Persistance des douleurs
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Hématome : pouvant nécessiter une nouvelle intervention pour évacuation
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Lésion nerveuse en particulier du nerf radial
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Raideur du coude pouvant durer plusieurs mois
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Arrêt d&apos;activité sportive et/ou changement de poste de
                travail malgré la chirurgie
              </Typography>
            </List.Item>
          </List>
        </MainPart>
      </div>
    </div>
    <RandomOp type="coude" />
  </Layout>
);

export default EpicondyliteLaterale;
